import React from 'react';
import ReactDOM from 'react-dom';
import logo from "./logo.png";
import './styles.css';

function Modal(props) {
  if (!props.isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="Modal">
      <div className="Modal__container_loading">
        <div className="text-center">
          <div className="spinner-border" style={{width:'6rem',height:'6rem'}} role="status">
            
            <img className="btn-logo1" src={logo}/>
          </div>
          <p className='text-white mt-2' >CARGANDO... </p>
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

export default Modal;