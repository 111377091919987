import React ,{StyleSheet} from 'react'
import "./botones.css";


const TakeCourse = () => {

  
  return (
    <div className="contenido-3">
    {/* code here */}
    <div className="menu">
      <ul className="menu-list">
        <li className="menu-item"><button className="menu-button">Muebles para dormitorio</button></li>
        <li className="menu-item"><button className="menu-button">Muebles para cocina</button></li>
        <li className="menu-item"><button className="menu-button">Muebles para sala</button></li>
        <li className="menu-item"><button className="menu-button">Decoracion dormitorio</button></li>
        <li className="menu-item"><button className="menu-button">Decoracion dormcocinaitorio</button></li>
        <li className="menu-item"><button className="menu-button">Decoracion sala </button></li>
      </ul>
      
    </div>
  </div>
  
  )
}


export default TakeCourse

