import React, { useEffect, useState } from 'react';
import  {useCallback} from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Router from './routes'
import InfoContext from "./globalComponents/InfoContext"
import ReactAudioPlayer from 'react-audio-player';
import soundtrack from "./globalComponents/musica.ogg";
import { isMobile } from 'react-device-detect';

function App() {
  const handle = useFullScreenHandle();
  const [muted, setMuted]=useState(false)
  const [fullsc, setFullsc]=useState(false)

  useEffect(() => {
    if(fullsc==true){
    handle.enter()
    setFullsc(false)
  }
  }, [fullsc]);

  useEffect(() => {
    if(isMobile){
      alert("Porfavor girar tu pantalla")
    }
    
  }, [isMobile]);
  

  return (

    <div>

      <FullScreen handle={handle}>
        <div className="App">
          <InfoContext.Provider value={{muted, setMuted,fullsc, setFullsc}}>
            <Router/>
            <ReactAudioPlayer
              src={soundtrack}
              autoPlay={true}
              controls={false}
              muted={muted}
              listenInterval={1000}
              autoplay
              loop
              volume={ 0.4}
            />
          </InfoContext.Provider>

        </div>
      </FullScreen>
    </div>

    
  );
}

export default App;
