import React,{useEffect,useState,useContext} from "react"
import InfoContext from "../../globalComponents/InfoContext"
import "./estilo.css";
import "./stand2.css";
import {NavLink} from "react-router-dom"
import fondo from "./auditorio.png";
import Loading from '../../globalComponents/Modal/loading';




//<YouTube videoId="2g811Eo7K8U" opts={opts}  />

const TakeCourse = () => {
  const {setMuted} =useContext(InfoContext)
  const [isLoaded, setIsLoaded] = React.useState(false);
  useEffect(() => {setMuted(false)}, []);

  return (
    <div >
      <div className="body3">
        <div className="fondo-auditorio">
        

        <NavLink to="/kadishas" className="stand-1" ></NavLink>
        
        
        <img onLoad={() => {setIsLoaded(true);}}  className="fondo-auditorio" src={fondo}/>
        </div>
      </div>
      <Loading isOpen={!isLoaded} />
    </div>
  )

}


/*


<NavLink to="/interbank" className="stand-2"></NavLink>
        <NavLink to="/esan" className="stand-3"></NavLink>*/

/*

<NavLink to="/camila" ><img className="stand-2" src={stand2}/></NavLink>
        <NavLink to="/kuru" ><img className="stand-3" src={stand3}/></NavLink>
        <NavLink to="/panorama" ><img className="stand-4" src={stand4}/></NavLink>
*/

export default TakeCourse

