import React ,{StyleSheet} from 'react'
import "./botones.css";


const TakeCourse = () => {

  
  return (
    <div className="contenido-4">
    {/* code here */}
    <div className="menu">
      

      <ul className="menu-list">
        <li className="menu-item"><button className="menu-button">Auditoria Interna</button></li>
        <li className="menu-item"><button className="menu-button">Auditoria Externa</button></li>
        <li className="menu-item"><button className="menu-button">Servicio de cliente incognito</button></li>
        <li className="menu-item"><button className="menu-button">Marketing digital</button></li>
        <li className="menu-item"><button className="menu-button">Contabilidad</button></li>
        <li className="menu-item"><button className="menu-button">Microblanding </button></li>
      </ul>
      
    </div>
  </div>
  
  )
}


export default TakeCourse

