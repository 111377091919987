
import React,{useEffect,useState,useContext} from "react"
import "./estilo.css";
import {NavLink} from "react-router-dom"
import Botones from './botones'
import Loading from '../../globalComponents/Modal/loading';
import InfoContext from "../../globalComponents/InfoContext"
import recepcion from "./recepcion.png";

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
};



//<YouTube videoId="2g811Eo7K8U" opts={opts}  />

const TakeCourse = () => {
  const {setMuted} =useContext(InfoContext)
  const [isLoaded, setIsLoaded] = React.useState(false); 
  useEffect(() => {setMuted(false)}, []);

  return (
    <div >
      <div className="body3">
        <div className="contenido2">
        
        <NavLink to="/informacion" ><div className="btn-info" /></NavLink>
        <NavLink to="/live" ><div className="btn-auditorio" /></NavLink>
        <NavLink to="/auditorio" ><div className="btn-feria" /></NavLink>
        <NavLink to="/live2" ><div className="btn-sum" /></NavLink>
        <NavLink to="/informacion" ><div className="btn-informacion" /></NavLink>
        
        <img onLoad={() => {setIsLoaded(true);}}  className="contenido2" src={recepcion}/>
        </div>
      </div>
      <Loading isOpen={!isLoaded} />
    </div>
  )

}


export default TakeCourse

