import React,{useEffect,useState,useContext} from "react"
import "./estilo.css";
import "./radial.css";

import InfoContext from "../../globalComponents/InfoContext"
//import 'node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'
import Loading from '../../globalComponents/Modal/loading';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import Whatshapp from "./wsp"
import ReactAudioPlayer from 'react-audio-player';
import fondo from "./fondos/interbank.png";
import soundtrack from "./musica.ogg";



const numero="927376872"





const TakeCourse = (props) => {
  const {setMuted} =useContext(InfoContext)
  const {nombre}=props.match.params
  useEffect(() => {setMuted(true)}, []);
  const [isOpen, setOpen] = useState(false)
  const [isLoaded, setIsLoaded] = React.useState(false); 
  
  return (
    <div className="body4">
      <div className="body3">
        <div className="fondo-stand" >
        <div>
        
        <ModalVideo channel='custom' autoplay isOpen={isOpen} url="https://shirleyarroe.s3.amazonaws.com/lahuella/interbank.mp4" onClose={() => setOpen(false)} />

        <a  href={"https://www.esan.edu.pe/"} className="imagen1" target="_blank"></a>
        <a  href={"https://interbank.pe/"} className="imagen3" target="_blank"></a>
        <a  onClick={()=> setOpen(true) } className="imagen2" target="_blank"></a>

        <img onLoad={() => {setIsLoaded(true);}}  className="fondo-stand" src={fondo}/>
        </div>
        </div>
      </div>
      <Whatshapp numero={numero}/>
      <ReactAudioPlayer
          src={soundtrack}
          autoPlay={true}
          controls={false}
          listenInterval={1000}
          autoplay
          loop
          volume={ 0.4}
        />
      
      <Loading isOpen={!isLoaded} />
    </div>
  )

}


/*

  <img className="img1" src={img1}/>
          <img className="img2" src={img2}/>
          <img className="img3" src={img3}/>
          <img className="img4" src={img4}/>
          <NavLink to="/video/dVer_cMreQg" ><img className="img5" src={img5}/></NavLink>
        

*/

export default TakeCourse

