import React ,{StyleSheet} from 'react'
import "./botones.css";


const TakeCourse = () => {

  
  return (
    <div className="contenido-1">
    {/* code here */}
    <div className="menu">
      
      <ul className="menu-list">
        <li className="menu-item"><button className="menu-button">Polos</button></li>
        <li className="menu-item"><button className="menu-button">Tops</button></li>
        <li className="menu-item"><button className="menu-button">Ropa de Playa</button></li>
        <li className="menu-item"><button className="menu-button">Vestido de verano</button></li>
        <li className="menu-item"><button className="menu-button">jeans</button></li>
        <li className="menu-item"><button className="menu-button">Tacones</button></li>
        <li className="menu-item"><button className="menu-button">Zapatillas</button></li>
      </ul>
      
    </div>
  </div>
  
  )
}


export default TakeCourse

