import React, { Component } from 'react'
import { BrowserRouter , Route, Switch, Redirect } from "react-router-dom"

//importar componentes
//import Header from './globalComponents/Header'
import Footer from './globalComponents/Footer2'
import Live2 from './pages/Live2'
import Live from './pages/Live1'
import Auditorio from './pages/Auditorio'

import Kadishas from './pages/Stand/kadishas'
import Interbank from './pages/Stand/interbank'
import Esan from './pages/Stand/esan'

import Stand from './pages/Stand'


import Informacion from './pages/Informacion'
import Start from './pages/Start'

import Error404 from './pages/404'

import { connect } from 'react-redux'
import * as auxActions from './actions/auxActions'
import * as loginActions from './actions/loginActions'
import * as dataUserActions from './actions/dataUserActions'



//import Header2 from './globalComponents/Header2'
//import * as loginActions from './actions/loginActions'
import socketIOClient from "socket.io-client";
import Global from './services/Global'
const { ocultarHeaderFooter } = auxActions
const { SaveLogin } = loginActions
const { getUserThemes, Notifications } = dataUserActions

class Routes extends Component {
    API = Global.API
    socket = socketIOClient(this.API);

    UNSAFE_componentWillMount(){
        if(localStorage.getItem('JWT')){
            this.props.getUserThemes()
           if(this.props.dataUserReducer.Token_404){
            this.props.SaveLogin(false)
           }else{
            this.props.SaveLogin(true)
            
           }
        }else{
            this.props.SaveLogin(false)
        }
        
    }


    

    render() {
        //console.log('this.props',this.props)
        const viewHF = this.props.auxReducer.viewHeaderFooter
        const LoginState = this.props.loginReducer.Login 
        return (
            <BrowserRouter>

                <Switch>
                    <Route exact path="/" component={Start} /> 
                    <Route exact path="/informacion" component={Informacion} />
                    <Route exact path="/auditorio" component={Auditorio} /> 
                    <Route exact path="/home" component={Start} />
                    <Route exact path="/live" component={Live} />
                    <Route exact path="/live2" component={Live2} />


                    <Route exact path="/kadishas" component={Kadishas} /> 
                    <Route exact path="/interbank" component={Interbank} /> 
                    <Route exact path="/esan" component={Esan} /> 
                    <Route exact path="/stand/:nombre" component={Stand} /> 
                    <Route component={Error404} />
                </Switch>
                <Footer></Footer>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = ({ auxReducer, loginReducer,dataUserReducer }) => {
    return { auxReducer, loginReducer,dataUserReducer }
}

const mapDispatchToProps = {
    ocultarHeaderFooter,SaveLogin,getUserThemes,Notifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
