
import React,{useEffect,useState,useContext} from "react"
import fondo from "./fondo.png";
import InfoContext from "../../globalComponents/InfoContext"
import total from "./imagen.png";
import Loading from '../../globalComponents/Modal/loading';
import Botones1 from "../Auditorio/botones1";
import Botones2 from "../Auditorio/botones2";
import Botones3 from "../Auditorio/botones3";
import Botones4 from "../Auditorio/botones4";
import {NavLink} from "react-router-dom"
import "./stand2.css";
const opts = {
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
};



//

const TakeCourse = () => {
  const {setMuted} =useContext(InfoContext)
  const [isLoaded, setIsLoaded] = React.useState(false); 

  useEffect(() => {setMuted(true)}, []);
  return (
    <div >
      <div className="body3">
        <div className="fondo-informacion">
        <img className="total" src={total}/>
          <figure>
          <NavLink className="sala1-link" to="/auditorio" ></NavLink>
            <figcaption><Botones1/></figcaption>
          </figure>
          <figure>
          <NavLink className="sala2-link" to="/auditorio" ></NavLink>
            <figcaption><Botones2/></figcaption>
          </figure>
          <figure>
          <NavLink className="sala3-link" to="/auditorio" ></NavLink>
            <figcaption><Botones3/></figcaption>
          </figure>
          <figure>
            <NavLink className="sala4-link" to="/auditorio" ></NavLink>
            <figcaption><Botones4/></figcaption>
          </figure>

          <img onLoad={() => {setIsLoaded(true);}}  className="fondo-informacion" src={fondo}/>
          
        
        </div>
      </div>
      <Loading isOpen={!isLoaded} />
    </div>
  )

}


export default TakeCourse

