import React,{useEffect,useState,useContext} from "react"
import Loading from '../../globalComponents/Modal/loading';
import fondo from "./live.png";
const TakeCourse = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  return (
  <div className="body4">
    <div className="contenido2-live">
      
      <iframe className="contenido-video2" src="https://vimeo.com/event/1712075/embed" 
          frameborder="0" allow="autoplay; fullscreen; picture-in-picture" 
          allowfullscreen >
      </iframe>
      <img onLoad={() => {setIsLoaded(true);}}  className="contenido2-live" src={fondo}/>
      
    </div>
    <Loading isOpen={!isLoaded} />
  </div>
  )
}



export default TakeCourse

