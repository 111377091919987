import React ,{StyleSheet} from 'react'
import "./botones.css";


const TakeCourse = () => {

  
  return (
    <div className="contenido-2">
    {/* code here */}
    <div className="menu">
      
      <ul className="menu-list">
        <li className="menu-item"><button className="menu-button">Cuidado del rostro</button></li>
        <li className="menu-item"><button className="menu-button">Cuidsado capilar</button></li>
        <li className="menu-item"><button className="menu-button">Tratamiento capilar</button></li>
        <li className="menu-item"><button className="menu-button">Perfumes</button></li>
        <li className="menu-item"><button className="menu-button">Maquillajes</button></li>
        <li className="menu-item"><button className="menu-button">Cuidado del cuerpo</button></li>
        <li className="menu-item"><button className="menu-button">Limpieza personal</button></li>
      </ul>
      
    </div>
  </div>
  
  )
}


export default TakeCourse

