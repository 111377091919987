import React ,{StyleSheet} from 'react'
import "./btn-wsp.css";



//<YouTube videoId="2g811Eo7K8U" opts={opts}  />

const Whashapp = ({numero}) => {

  return (
    <div
      className="phone-call cbh-phone cbh-green cbh-show  cbh-static"
      id="clbh_phone_div"
      style={{}}>
      
      <a id="WhatsApp-button" href={"https://api.whatsapp.com/send?phone=51"+numero+"&text=Hola"} target="_blank"
        className="phoneJs" title="WhatsApp 360imagem">
        <div className="cbh-ph-circle" />
        <div className="cbh-ph-circle-fill" />
        <div className="cbh-ph-img-circle1" />
      </a>
    </div>
    
  )

}


export default Whashapp

