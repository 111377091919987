import axios from 'axios'
import { THEMES_OF_USER, IS_LOADING,TOKEN_404, NOTIFICATIONS } from './types'
import Global from '../services/Global'

const API = Global.API

export const getUserThemes = () => async (dispatch,getState) => {
    const JWT = localStorage.getItem("JWT")
    const { Login } = getState().loginReducer
    console.log('login desde dataUserReducer:',Login)
    dispatch({
        type: IS_LOADING,
        payload: true
    })
    
    try {
        const resUserThemes = await axios.get(API + 'client/user/temas', { headers: { 'x-access-token': JWT } })
        const resInstr = await axios.get(API + 'client/instructor')
        //console.log('data UserThemes:', resUserThemes.data)
        //console.log('data Instructors:',resInstr.data)
        let newdata = []
        resUserThemes.data.map(e=>{
            const instr = resInstr.data.filter(instr=>instr.idinstructor===e.instructor_idinstructor)
            //console.log('instr select',instr[0])
            const fila = {...e,instructor:instr[0].nombre}
            newdata=[...newdata,fila]
            return false
        })
        //console.log('newwww dataa:',newdata)
        dispatch({
            type: THEMES_OF_USER,
            payload: newdata
        })
    } catch (e) {
        //console.log(e.response)
        if (e.response&&e.response.status === 401) {
            console.log('error 401')
            dispatch({
                type: TOKEN_404
            })
            localStorage.clear()
        }
    }
    dispatch({
        type: IS_LOADING,
        payload: false
    })
}

export const Notifications = (not) => async (dispatch) => {
    console.log('notifications:',not)
    dispatch({
        type: NOTIFICATIONS,
        payload: not
    })
}